@import (reference) "../../../styles/less/colors.less";
@import (reference) "../../../styles/less/variables.less";

.detail-page-side-panel {
  .asset,
  .component {
    border-top: solid 1px @color-gray-90;
    cursor: pointer;

    .details {
      min-height: @spacing-xx-large;
      padding: @spacing-medium;
      transition: all 0.5s ease;
      color: @color-gray-0;

      a {
        * {
          pointer-events: none;
        }
      }

      .link-label {
        display: flex;
        text-decoration: none;
      }

      &:hover {
        background-color: @color-gray-70;
        .name {
          text-decoration: underline;
        }
      }

      .name-icon {
        display: flex;
        flex: 1;
        align-items: center;
        opacity: 0.33;
        max-width: 85%;

        .icon {
          margin-right: @spacing-large;
          color: @color-gray-0;
        }

        .name {
          color: @color-gray-0;
          word-break: break-all;
          overflow: hidden;
          text-overflow: ellipsis;
          display: inline;

          &.no-icon {
            margin-left: @spacing-xx-large;
            margin-right: @spacing-large;
          }
        }
      }

      .risk {
        border-radius: 50%;
        height: @icon-font-size-base;
        min-height: @icon-font-size-base;
        width: @icon-font-size-base;
        min-width: @icon-font-size-base;
      }

      .risk.High {
        background-color: @color-risk-high;
      }

      .risk.Medium {
        background-color: @color-risk-medium;
      }

      .risk.Low {
        background-color: @color-risk-low;
      }

      .status {
        min-width: @spacing-xx-large;

        .icon {
          vertical-align: middle;
        }
      }

      &:hover {
        .name-icon {
          opacity: 1;
        }
      }
    }
  }

  .details {
    .name-icon {
      max-width: 92%;
    }
  }

  .component.active .details {
    .name-icon {
      opacity: 1;
    }
  }
  .asset {
    border-top: none;
  }

  .error {
    background-color: @color-status-red;
    display: flex;
    align-items: center;
    padding: @spacing-medium;

    .icon {
      margin-right: @spacing-medium;
    }
  }
}
