@import (reference) "../../../../styles/less/colors.less";

.nameplate-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: @color-primary;

  .nameplate-icons-group {
    display: flex;
    align-items: center;

    .spinner {
      margin: 0 auto;
    }

    &.watch-icon-container {
      margin-right: auto;
    }
  }

  .icon {
    cursor: pointer;
    padding: 4px;
  }

  .c-tooltip {
    display: flex;
    &:hover::before {
      bottom: 30px;
    }
  }

  .watched-icon {
    width: 32px;
  }
}
