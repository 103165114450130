@import (reference) "../../../../../../styles/less/colors.less";
@import (reference) "../../../../../../styles/less/variables.less";

@age-color: @color-blue-50;
@adjusted-age-color: #206398;
@customer-color: #47a092;
@manufacturer-color: #7c71a7;

.aging-visualization-chart .aging-visualization-chart-legend {
  display: flex;
  justify-content: space-between;
  font-size: @font-size-small;

  .values-legend,
  .life-expectancy-legend {
    display: flex;

    > div {
      display: flex;
      align-items: center;

      span + span {
        margin-left: 8px;
      }
    }

    > div + div {
      margin-left: 24px;
    }

    [class$="-indicator"] {
      display: inline-block;
      height: 1.5ch;
      width: 1.5ch;
    }

    .age-indicator {
      border: solid 2px @age-color;
      border-radius: 2px;
      background: fade(@age-color, 50%);
    }

    .adjusted-age-indicator {
      border: solid 2px @adjusted-age-color;
      border-radius: 2px;
      background: fade(@adjusted-age-color, 50%);
    }

    .customer-indicator,
    .manufacturer-indicator {
      height: 0ch;
      width: 4ch;
      border-style: dashed;
      border-width: 2px;
    }

    .customer-indicator {
      border-color: @customer-color;
    }

    .manufacturer-indicator {
      border-color: @manufacturer-color;
    }
  }
}
