@slide-panel-bg-color: rgba(0, 0, 0, 0.3);

.slide-panel {
  height: 0;
  width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10020;

  .container {
    background: @slide-panel-bg-color;
    display: none;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;

    .content {
      background-color: white;
      display: flex;
      flex-direction: column;
      height: 100%;
      max-height: 100vh;
      padding-left: 0;
      padding-right: 0;
      position: fixed;
      top: 0;
      right: 0;
      transform: translateX(100%);

      .header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .title {
          margin: 0;
          margin-bottom: 5px;
        }

        .icon {
          cursor: pointer;
          padding: 8px;
        }
      }

      .main {
        display: flex;
        flex-grow: 1;
        height: 0;
      }
    }
  }

  &.opened {
    .container {
      display: block;

      .content {
        transform: translateX(0);

        -moz-animation: slide-panel-content-open 0.3s ease;
        -webkit-animation: slide-panel-content-open 0.3s ease;
        animation: slide-panel-content-open 0.3s ease;
      }
    }
  }

  &.closing {
    .container {
      display: block;

      .content {
        transform: translateX(100%);

        // Animation time is related to slide panel status changing delay.
        // See SlidePanel.tsx file.
        -moz-animation: slide-panel-content-close 0.2s ease-out;
        -webkit-animation: slide-panel-content-close 0.2s ease-out;
        animation: slide-panel-content-close 0.2s ease-out;
      }
    }
  }

  &.closed {
    .container {
      display: none;

      .content {
        transform: translateX(100%);
      }
    }
  }
}

@-moz-keyframes slide-panel-content-open {
  0% {
    -moz-transform: translateX(100%);
  }
  100% {
    -moz-transform: translateX(0);
  }
}

@-webkit-keyframes slide-panel-content-open {
  0% {
    -webkit-transform: translateX(100%);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}

@keyframes slide-panel-content-open {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0);
  }
}

@-moz-keyframes slide-panel-content-close {
  0% {
    -moz-transform: translateX(0);
  }
  100% {
    -moz-transform: translateX(100%);
  }
}

@-webkit-keyframes slide-panel-content-close {
  0% {
    -webkit-transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(100%);
  }
}

@keyframes slide-panel-content-close {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
