@import (reference) "../../../../../styles/less/colors.less";
@import (reference) "../../../../../styles/less/variables.less";

.detail-page-issues-actions-modal {
  padding: 0 !important;

  form {
    .actions {
      padding: 20px;
    }

    .work-request,
    .work-order {
      align-items: center;
      border-top: solid 1px @color-gray-10;
      display: flex;
      flex-basis: auto;
      padding: 10px 0;

      > div + div {
        margin-left: 20px;
      }

      .summary {
        flex-grow: 1;
      }

      .details {
        min-width: 200px;

        .details-label {
          margin-right: 5px;
        }
      }
    }

    .work-request {
      margin: 0 20px;
    }

    .work-orders {
      padding: 0 20px;
    }

    .no-actions-available {
      margin: 12px 24px;
    }

    .input-submit {
      margin-left: 20px;
    }
  }

  .action-description-tooltip {
    font-size: @font-size-small;
    font-weight: 600;
    max-width: 600px;
  }
}

.modal-window-overlay
  .modal-window-mixed
  .modal-window
  .detail-page-issues-actions-modal
  .scrollarea {
  max-height: calc(80vh - 220px);
}
