@import (reference) "../../styles/less/colors.less";
@import (reference) "../../styles/less/variables.less";

@grid-bg-color: white;
@grid-header-bg-color: @color-gray-10;
@grid-body-row-hover-bg-color: @color-gray-10;
@grid-body-row-selected-bg-color: @color-teal-10;
@grid-border-color: @color-gray-20;
@grid-padding: 10px;

.data-grid__parent {
  position: relative;
}

.data-grid__scroll {
  overflow: auto;
}

.data-grid__parent,
.data-grid__scroll {
  [class^="col"] {
    position: static;
  }
}

.common.data-grid {
  background: @grid-bg-color;
  border: solid 1px @grid-border-color;
  box-sizing: border-box;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  .grid-header,
  .grid-body {
    .grid-row {
      display: flex;
      flex-wrap: nowrap;
      overflow: hidden;
      width: 100%;

      .grid-cell {
        .grid-cell-content {
          padding: @grid-padding;

          .grid-cell-name-indicator {
            &.check-all-header {
              display: flex;
              justify-content: center;
            }
          }

          .grid-cell-name-indicator {
            &.check-all-header {
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }
  }

  .grid-header {
    background-color: @grid-header-bg-color;

    .grid-row {
      .grid-cell {
        .grid-cell-content {
          > div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;

            .name {
              overflow: hidden;
              word-wrap: break-word;
            }

            .indicator {
              display: flex;
              align-items: center;

              .icon {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-left: 5px;

                &.none {
                  display: none;
                }

                &.asc {
                  transform: rotate(-90deg);
                }

                &.desc {
                  transform: rotate(90deg);
                }
              }
            }
          }
        }

        &.sortable {
          cursor: pointer;
        }

        &.checkbox-column-header {
          padding: @grid-padding;
          width: 55px;
          display: flex;
          justify-content: center;
        }
      }
    }

    &.fixed {
      position: absolute;
      z-index: 100;
    }
  }

  .grid-body {
    font-size: @font-size-base;

    &.processing {
      display: flex;
      align-items: center;
      justify-content: center;

      .spinner {
        margin: 0;
      }
    }

    &.failed {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: @grid-padding;

      .icon {
        color: @color-status-red;
        font-size: 18px;
        margin-right: @grid-padding;
      }
    }

    &.no-data {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: @grid-padding;

      .icon {
        color: @color-status-blue;
        font-size: 18px;
        margin-right: @grid-padding;
        transform: rotate(180deg);
      }
    }

    .grid-row-container {
      border-top: solid 1px @grid-border-color;

      .grid-row {
        .grid-cell {
          -ms-word-break: break-all;
          word-break: break-word;

          &.frozen {
            background-color: @color-widget-background;
          }

          &.checkbox-column-body {
            cursor: default;
            padding: @grid-padding;
            width: 55px;
            display: flex;
            justify-content: center;
          }
        }
      }

      .grid-row:hover {
        .grid-cell {
          background-color: @grid-body-row-hover-bg-color;
        }

        .grid-row-actions {
          .actions {
            transform: translate(0);

            -webkit-animation: actions-animation 0.3s ease;
            -moz-animation: actions-animation 0.3s ease;
            animation: actions-animation 0.3s ease;
          }
        }
      }

      .grid-row.selected {
        .grid-cell {
          background-color: @grid-body-row-selected-bg-color;
        }
      }

      .grid-row.checked {
        .grid-cell {
          background-color: fade(@color-teal-90, 8%);
        }
      }

      .grid-row-actions {
        position: relative;
        border-left: solid 1px @grid-border-color;
        width: 0;

        .actions {
          display: flex;
          background-color: white;
          height: 100%;
          position: absolute;
          right: 0;
          transform: translate(110%);

          .icon {
            cursor: pointer;
            display: inline-flex;
            font-size: 24px;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 40px;
          }
        }
      }

      &.skeleton-row {
        .grid-row {
          .grid-cell {
            padding: @grid-padding;

            .first,
            .second {
              background: linear-gradient(90deg, #fafafa, #dcdcdc);
              background-size: 400% 400%;

              height: 12px;

              -webkit-animation: skeleton-animation 2s ease infinite;
              -moz-animation: skeleton-animation 2s ease infinite;
              animation: skeleton-animation 2s ease infinite;
            }

            .first {
              margin-bottom: @grid-padding;
              width: 75%;
            }

            .second {
              width: 45%;
            }
          }
        }
      }

      &.message-row {
        .grid-row {
          display: flex;
          align-items: center;
          width: 100%;

          > div:first-of-type {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: @color-status-blue;
            height: 100%;
            min-height: 50px;
            width: 50px;

            .icon {
              color: white;
              font-size: 22px;
            }
          }

          > div:last-of-type {
            margin-left: @grid-padding;
          }
        }
      }
    }
  }

  .grid-footer {
    background-color: @color-widget-background;
    border-top: solid 1px @grid-border-color;
    border-bottom: solid 1px @grid-border-color;

    .grid-row {
      .grid-row-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: @grid-padding;
      }
    }

    &.fixed {
      position: absolute;
      z-index: 100;

      .grid-row {
        height: 100%;
      }
    }
  }

  &.column-border {
    .grid-header,
    .grid-body {
      .grid-row {
        .grid-cell + .grid-cell {
          border-left: solid 1px @grid-border-color;
        }
      }
    }
  }

  .clickable {
    cursor: pointer;
  }
}

@-webkit-keyframes skeleton-animation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@-moz-keyframes skeleton-animation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}
@keyframes skeleton-animation {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 100%;
  }
  100% {
    background-position: 0% 100%;
  }
}

@-webkit-keyframes actions-animation {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
@-moz-keyframes actions-animation {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
@keyframes actions-animation {
  0% {
    transform: translate(100%);
  }
  100% {
    transform: translate(0);
  }
}
