@import (reference) "../../../../../styles/less/colors.less";

h2.ant-typography.asset-ribbon-number-value {
  font-weight: normal;
  margin-bottom: 0;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &.blue-accent {
    color: @color-primary;
  }
}
