@import (reference) "styles/less/colors.less";
@import (reference) "styles/less/variables.less";

.asset-detail-replacement-score {
  display: flex;
  justify-content: center;
  align-items: center;

  &-grid-row {
    .ant-col {
      height: 100%;
    }
  }

  &-total {
    & > span {
      margin-right: 0.25em;
    }
  }

  .asset-ribbon-column {
    width: 100%;
    align-items: center;
  }

  .replacement-score {
    display: flex;
    font-weight: 400;
    margin: 0 auto;
    color: @color-text-primary-on-light;
    line-height: 1;

    h2 {
      &:nth-of-type(2) {
        margin-top: 0;
      }

      &.warning {
        color: @color-status-orange;
      }
    }

    &.smaller {
      font-size: 19px;

      h2 {
        font-size: 19px;
      }
    }
  }

  .family-info-popover-icon {
    font-size: 16px;
    padding-left: 5px;
  }

  &-grid-row {
    .replacement-score {
      .ant-typography {
        line-height: 1;

        .separator {
          margin: 0 5px;
        }
      }
    }
  }

  &-out-of-column {
    display: flex;
    flex-direction: column;
    .ant-typography {
      line-height: 0.8;
      font-size: @font-size-small;
    }
  }

  .family-info-popover-off {
    opacity: 0.1;
    pointer-events: none;
  }
}
