@import (reference) "styles/less/colors.less";

.asset-detail-messages {
  .asset-ribbon-column {
    align-items: center;
  }
  &-alert {
    color: @color-status-orange;
    .asset-ribbon-number-value {
      color: @color-status-orange;
    }
  }

  &-warning:not(.asset-detail-messages-alert) {
    color: @color-status-yellow;
    .asset-ribbon-number-value {
      color: @color-status-yellow;
    }
    .icon {
      display: inline-block;
      transform: rotate(180deg);
    }
  }

  .header-messages {
    display: flex;
    align-items: flex-end;
  }
}
