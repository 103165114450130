@import (reference) "./colors.less";
@import (reference) "./variables.less";

@import "./reset.less";
@import "./antd.less";
@import "./asset-risk.less";
@import "./legacy.less";
@import "./bb.less";
@import "./columns.less";

a {
  font-weight: 600;
  font-size: @font-size-small;

  &.link-label {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.anchor {
  color: @color-primary;
  cursor: pointer;
  font-weight: 600;
  font-size: @font-size-small;
}

body {
  background-color: @color-app-background;
  font-family: @font-family;
  margin: 0;
  padding: 0;
}

* {
  /* Firefox start */

  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.25) rgba(0, 0, 0, 0);

  /* Firefox end */

  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
  }
}

.bold {
  font-weight: 600;
}

.pointer {
  cursor: pointer;
}
